<template>
  <section class="content_page">
    <b-row>
      <b-col sm="12">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <h4 class="card-title mb-2">Notification</h4>
          </div>
        </div>
      </b-col>
      <b-col sm="12">
        <section v-if="!postLoading">
          <div class="iq-card" v-for="(data, index) in notificationList" :key="index">
            <div class="iq-card-body">
              <ul class="notification-list m-0 p-0">
                <li class="d-flex align-items-center">
                  <div class="user-img img-fluid">
                    <div v-if="data.type == 6" class="ri-notification rounded-circle avatar-40">
                      <i class="ri-notification-2-line"></i>
                    </div>
                    <div v-else-if="data.type == 0" class="ri-notification rounded-circle avatar-40">
                      <img src="@/assets/images/public/circle_jci.png" class="rounded-circle avatar-40" />
                    </div>
                    <section v-else>
                      <img v-if="data.avatar" :src="data.avatar" class="rounded-circle avatar-40" />
                      <img v-else src="@/assets/images/public/circle_jci.png" class="rounded-circle avatar-40" />
                    </section>
                  </div>

                  <div class="media-support-info ml-3">
                    <div class="d-flex">
                      <h5>{{ data.nick_name }}</h5>
                      <p class="mb-0 ml-2">{{ data.message }}</p>
                    </div>
                    <p class="mb-0">{{ data.diff_date }}</p>
                  </div>
                  <div class="d-flex align-items-center">
                    <span v-if="[1,2,3,4,5].includes(data.type)" class="mr-3 iq-notify iq-bg-primary rounded">
                      <i class="ri-heart-line" v-if="data.type == 1"></i>
                      <i class="ri-heart-line" v-if="data.type == 2"></i>
                      <i class="ri-chat-4-line" v-if="data.type == 3"></i>
                      <i class="ri-notification-2-line" v-if="data.type == 4"></i>
                      <i class="ri-heart-line" v-if="data.type == 5"></i>
                      <!-- <i class="ri-notification-2-line" v-if="data.type == 6"></i> -->
                    </span>
                    <span v-if="[7,8,11].includes(data.type)" @click="routerGoTo(data)" class="mr-3 iq-notify iq-bg-primary rounded">
                      <i class="el-icon-view"></i>
                    </span>

                    <span class="d-inline-block" v-if="data.type!=0" v-b-tooltip.top title="Delete">
                      <a href="javascript:;" @click.stop="handleClickDel(data)"><i
                          class="ri-delete-bin-6-fill mr-2"></i></a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div v-if="postLoading">
          <el-skeleton animated :count="8">
            <template slot="template">
              <iq-card body-class="p-0">
                <div style="padding: 18px;" class="d-flex align-items-center justify-content-between">
                  <section class="d-flex align-items-center">
                    <el-skeleton-item variant="circle" style="width: 40px; height: 40px;" />
                    <div class="ml-3 d-flex flex-column">
                      <el-skeleton-item variant="text" style="width: 120px;" />
                      <el-skeleton-item variant="text" style="width: 200px;margin-top: 10px;" />
                    </div>
                  </section>
                  <section>
                    <el-skeleton-item variant="text" style="width: 22px;height: 22px;" />
                    <el-skeleton-item variant="text" style="width: 22px;height: 22px;margin-left: 10px;" />
                  </section>
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </div>

        <div v-if="notificationList.length" class="d-flex justify-content-end container">
          <el-pagination background layout="prev, pager, next" :page-size="per_page" @current-change="handleCurrentChange"
            :current-page.sync="page" :total="total" hide-on-single-page>
          </el-pagination>
        </div>
        <div style="background:#fff;padding:100px 20px;" v-else class="d-flex justify-content-center mt-3">
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
  import { socialvue } from "@/config/pluginInit";
  export default {
    name: "Notification",
    mounted() {
      socialvue.index();
    },
    data() {
      return {
        notificationList: [],
        page: 1,
        per_page: 8,
        total: 0,
        postLoading:false
      };
    },
    created() {
      this.getAllSystemList();
    },
    methods: {
      routerGoTo(data) {
        if(data.type==7){
          this.$router.push({path: `/event-detail?id=${data.join_id}&type=2&IsShowCheckout=0`,});
        }else if(data.type==8){
          this.$router.push({path: `/meeting-details?id=${data.join_id}&type=4&IsShowCheckout=0`,});
        }else if(data.type==11){
          if(data.status==1){
            this.$router.push({path: `/club`,});
          }else if(data.status==2){
            this.$router.push({path: `/becomeMember`,});
          }
        }
        /**
         * 1 = > 点赞文章
         * 2 = > 点赞评论
         * 3 = > 文章回复
         * 5 = > 艾特了你 || 帖子被艾特
         */
        // let whitelist = [1, 2, 3, 5];
        // if (type === "click") {
        //   if (whitelist.includes(data.type)) {
        //     this.$router.push({
        //       path: `/newsfeeds/${data.id}`,
        //     });
        //   }else{
        //     console.log(data.type,data.status);
        //   }
        // } else {
        //   return 'cursor-pointer'
        // }
      },
      handleClickDel(data) {
        this.$confirm("Are you sure to delete the message?", "Tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: 'Cancel',
          type: "warning",
        }).then(() => {
          this.$http
            .delSystemList({
              type: data.type,
              id: data.id,
            })
            .then((res) => {
              if (res.status == 200) {
                this.getAllSystemList();
              }
            });
        });
      },

      handleCurrentChange(page) {
        this.page = page
        this.getAllSystemList()
      },

      // 全部系统消息列表
      getAllSystemList() {
        this.postLoading = true
        this.$http.allSystemList({ page: this.page, per_page: this.per_page }).then((res) => {
          if (res.status == 200) {
            this.notificationList =res.data.data;
            this.total =res.data.total;
            setTimeout(() => {
              this.postLoading = false
            }, 0);
          }
        });
      },
    },
  };
</script>
<style src='' lang='scss' scoped>
  .ri-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5fbff;

    i {
      color: #50b5ff;
      font-size: 20px;
    }
  }

 
</style>